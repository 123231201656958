import { LoadingButton } from "@mui/lab";
import { Stack } from "@mui/material";
import useIsDesktop from "desktop/useIsDesktop";
import { Formik } from "formik";

import TextInput from "../../components/Form/Input/Text";
import Select from "../../components/Form/Select";
import { SEO } from "../../components/SEO";
import { BodyTextM, HeadingL } from "../../components/Typography";
import cs from "../../translations/cs.json";

function ChangeTelephoneNumberPage(): JSX.Element {
	const isDesktop = useIsDesktop();

	return (
		<>
			<Formik
				initialValues={{ telephoneNumber: [], country: "Česká republika" }}
			>
				<Stack
					spacing={5}
					sx={{
						p: { md: 5 },
						boxShadow: (theme) => ({
							md: theme.customShadows.close,
						}),
						backgroundColor: "white.main",
					}}
				>
					<Stack spacing={5}>
						<Stack>
							<HeadingL mb={3}>
								{cs.changeTelephoneNumberWithVerification.heading}
							</HeadingL>
							<BodyTextM
								sx={{
									color: "grey.dark",
								}}
							>
								{cs.changeTelephoneNumberWithVerification.description}
							</BodyTextM>
						</Stack>
						<Stack spacing={4}>
							<Select
								name={"country"}
								label={cs.changeTelephoneNumberWithVerification.diallingCode}
								disabled={false}
							>
								<option
									label={"Česká republika (+420)"}
									value={"Česká republika"}
								>
									Česká republika (+420)
								</option>
							</Select>
							<TextInput
								label={cs.changeTelephoneNumberWithVerification.telephoneNumber}
								name={"telephoneNumber"}
								placeholder={
									cs.changeTelephoneNumberWithVerification.enterTelephoneNumber
								}
							/>
						</Stack>
					</Stack>
					{isDesktop ? (
						<>
							<Stack
								sx={{ flexDirection: "row", justifyContent: "space-between" }}
							>
								<LoadingButton variant="outlined" size={"small"} sx={{ px: 7 }}>
									{cs.changeTelephoneNumberWithVerification.abort}
								</LoadingButton>
								<LoadingButton
									variant="contained"
									size={"small"}
									sx={{ px: 4 }}
								>
									{cs.changeTelephoneNumberWithVerification.sendCode}
								</LoadingButton>
							</Stack>
						</>
					) : (
						<>
							<Stack
								sx={{
									flexDirection: "column",
									justifyContent: "space-between",
								}}
								spacing={4}
							>
								<LoadingButton variant="outlined">
									{cs.changeTelephoneNumberWithVerification.abort}
								</LoadingButton>
								<LoadingButton variant="contained">
									{cs.changeTelephoneNumberWithVerification.sendCode}
								</LoadingButton>
							</Stack>
						</>
					)}
				</Stack>
			</Formik>
		</>
	);
}

export default ChangeTelephoneNumberPage;

export const Head = (): JSX.Element => (
	<SEO title={cs.changeTelephoneNumberWithVerification.heading} />
);
